.App {
  text-align: center;
}

textarea.form-control {
  height: auto;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.fixed-button-nav {
  position: fixed;
  width: 100%;
  z-index: 99999;
  top: 0;
  border-color: #fff;
  box-shadow: 0px 2px 15px rgb(18 66 101 / 8%);
}

.shadow-lg {
  /* box-shadow: 0 4rem 2rem rgb(0 0 0 / 18%) !important; */
  box-shadow: none !important;
  border-top-left-radius: 0;
}

.tab-pane {
  border-left: 1px solid #dddfeb !important;
  border-color: #dddfeb #dddfeb #fff;
  border-right: 1px solid #dddfeb !important;
  border-bottom: 1px solid #dddfeb !important;
}

.border-custom {
  border-left: 1px solid #dddfeb !important;
  border-color: #dddfeb #dddfeb #fff;
  border-right: 1px solid #dddfeb !important;
  border-top: 1px solid #dddfeb !important;
  border-bottom: 1px solid #dddfeb !important;
  border-top-right-radius: 0;
}

.tab-content .btn-light {
  background: transparent;
  background-color: transparent !important;
}

.tab-content .btn-light:focus {
  color: #6e707e !important;
  background-color: #fff !important;
  border-color: #bac8f3 !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.2rem rgb(78 115 223 / 25%) !important;
}

.text-black {
  color: hsl(0, 0%, 20%) !important;
}

.pdf {
  margin: 0 auto;
  float: none;
  width: 95%;
}

.pdf iframe {

  width: 100%;
  min-height: 100vh;
}

span.error {
  font-size: 10px !important;
  color: red
}

.z-99 {
  z-index: 3;
}

.text-red,
.text-red div[class*="-placeholder"] {
  color: red !important;
}

.status-switch .react-switch-bg div {
  width: 65px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

a:not([href]) {
  color: #fff !important;
}

.line-height-2 {
  line-height: 2.5;
}

.backgroundColor-lightdark {
  background-color: #bfbfbf !important;
}

.text-12 {
  font-size: 12px;
  color: black;
  font-weight: 600;
}

.is-invalid-s div[class*="-control"] {
  border-color: #e74a3b;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e74a3b' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e74a3b' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.is-invalid-s div[class*="-placeholder"] {
  color: #e74a3b;
}

th.buttons {
  width: 75px;
}

.w-45 {
  width: 45px;
}

.price-input .form-outline {
  width: calc(100% - 50px);
}

.no-shadow nav {
  box-shadow: none !important;
  padding: 0px !important
}

.radio-custom-label:before {
  content: '';
  /* background: #fff; */
  border: 0;
  display: inline-block;
  vertical-align: middle;
  width: 50px;
  height: 50px;
  padding: 7px;
  margin-right: 0px;
  text-align: center;
  background: transparent;
}

.radio-custom {
  opacity: 0;
  margin: 0 -13px 0 0;
  cursor: pointer;
}

.radio-custom-label {
  cursor: pointer;
}

.radio-custom:checked+.radio-custom-label:before {
  content: "\f00c";
  /* font-family: "Font Awesome 5 Free"; */
  color: #fff;
  font-size: 35px;
}

.btn-edit,
.btn-print {
  background: transparent;
  border: none;
}

a.btn-edit {
  color: #212529 !important;
}

.btn-delete {
  background: transparent;
  border: none;
  color: #e74a3b;
}

.btn-header .btn-close {
  margin-top: 0.5rem !important;
}

.table thead th {
  font-weight: 700;
}

.table th,
.table td {
  padding: 0;
  vertical-align: top;
  border-top: 1px solid #e3e6f0;
}

.nav-link.active {
  background: #f5f5f5 !important;
  color: #4e73df !important;
}

.top-menu {
  display: -webkit-inline-box;
}

.top-menu li {
  list-style-type: none;
  /* flex-grow: 1;
  flex-basis: 0; */
  cursor: pointer;
  color: #fff;
  border-right: 1px solid;
}

.top-menu li:last-child {
  border-right: none;
}

.top-menu li a {
  color: #fff !important;
}

.topbar {
  height: 2.5rem !important;
}

nav.topbar {
  background-color: rgb(45, 44, 44);
}

.sub-menu {
  min-height: calc(100vh - 120px);
  z-index: 1;
}

.myClassName>div {
  height: 29px;
}

.topbar {
  z-index: 9;
}

.sales-table td,
.purchase-table td {
  padding: 2px !important;
}

.sales-table tr th {
  padding-left: 2px;
  padding-right: 2px;
}

.purchase-table td {
  vertical-align: middle !important;
  font-size: 12px;
}

.purchase-table th {
  font-size: 12px;
  color: #000;
  font-weight: 600 !important;
  vertical-align: middle !important;
}

.header-tools span {
  padding: 0 .5rem !important;
  text-transform: none !important;
}

.pagination li a {
  color: #000 !important;
  padding: 0 10px;
}

.pagination li:hover:not(.active) {
  background-color: #4e73df;
  color: white !important;
}

.pagination li:hover:not(.active) a,
.pagination li.active a {
  color: white !important;
}

.pagination li.disabled a {
  color: gray !important
}

.pagination li.disabled:hover,
.pagination li.disabled:hover a {
  background: none !important;
  color: gray !important;
  cursor: not-allowed;
}

#sidebarToggleTop {
  position: fixed;
  top: 0;
  z-index: 999999999;
  border-radius: 0;
  width: 40px;
  height: 40px;
  outline: none;
  border: none;
  color: #fff;
  box-shadow: none;
}

@media screen and (max-width: 750px) {
  .topbar {
    left: 40px;
    width: calc(100% - 40px) !important;
  }

  .nav-mobile {
    left: 0 !important;
    width: 100% !important;
  }
}

/* tr td:nth-child(odd){ 
  background: #b8d1f3;
} 
tr th, tr td {
  text-align: center;
} */
tr th:nth-child(even),
tr td:nth-child(even) {
  background: #f8f9fc;
}


.sales-table tr th {
  padding-left: 2px;
  padding-right: 2px;
}


/* AccountingPlansNew */

.accountingAdd-btn {
  position: absolute;
  right: 11% !important;
  top: -30px !important;
}

.accountingEdit-btn {
  position: absolute;
  right: 18% !important;
  top: -30px !important;
}

.accountingDelete-btn {
  position: absolute;
  right: 1% !important;
  top: -30px !important;
}

.accountingDelete-btn i {
  color: red;
}

.accountingDelete-btn i:hover {
  color: #e74a3b;
}

.accountingAdd-btn i {
  color: #4e73df;
}

.accountingEdit-btn i {
  color: black;
}

.accountingAdd-btn i:hover,
.accountingEdit-btn i:hover {
  color: #000fff;
}

.arrowLeft i {
  color: black;
  padding: 5px;
}

.arrowLeft i:hover {
  font-size: 25px;
  color: #000fff;
}

/* react confirm alert */

.react-confirm-alert-overlay {
  z-index: 10000 !important;
}

/* Sale */
#Sale .no-colors tr th,
#Sale .no-colors tr td,
#addTransaction .no-colors tr th,
#addTransaction .no-colors tr td,
#financialOrder .no-colors tr th,
#financialOrder .no-colors tr td,
#payments .no-colors tr th,
#payments .no-colors tr td,
#topSuppliers .no-colors tr th,
#topSuppliers .no-colors tr td {
  background: none;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.top-menu::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  /* border-radius: 10px; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: gray;
  /* border-radius: 10px; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: grey;
}

.btn-add-item-from-purchase {
  position: absolute;
  top: 0;
  right: 35px;
  font-size: 25px;
  font-weight: bold;
  line-height: 1.2;
  background: none;
  border: none;
  box-shadow: none;
}

.btn-transport-save {
  float: right;
  margin-top: -35px;
  font-size: 12px !important;
  padding: 4px 15px 1px 15px !important;
  text-transform: capitalize;
}

.transpot-table .partner-btn {
  top: -17px !important;
}

.calc-item {
  flex-basis: 100%;
}

.z9 .form-outline {
  /* z-index: 9; */
  position: relative;
}

#table-to-xls td {
  border: 1.0pt solid #000
}

.margin-7 {
  margin: -7px;
}


/* navi companyName */

#navi #company li {
  border: gray 2px solid;
  color: white;
}


.pLeft38 {
  padding-left: 38%;
}

.pLeft35 {
  padding-left: 35%;
}

.reportsBordersFi,
.reportsBordersTax,
.reportsBordersAcc,
.reportsBordersSt,
.reportsBordersPu,
.reportsBordersSa {
  background-color: #dee4e7;
  border-radius: 5px;
  padding: 4px;
  width: 75%;
}

.reportsBordersFi:hover {
  background-color: #0d6efd;
  font-weight: bold;
}

.reportsBordersTax:hover {
  background-color: #198754;
  font-weight: bold;
}

.reportsBordersAcc:hover {
  background-color: #dc3545;
  font-weight: bold;
}

.reportsBordersSt:hover {
  background-color: #ffc107;
  font-weight: bold;
}

.reportsBordersPu:hover {
  background-color: #0dcaf0;
  font-weight: bold;
}

.reportsBordersSa:hover {
  background-color: #212529;
  font-weight: bold;
}

.reportsBordersFi a,
.reportsBordersTax a,
.reportsBordersAcc a,
.reportsBordersSt a,
.reportsBordersPu a,
.reportsBordersSa a {
  color: #212529;
}

.reportsBordersFi a:hover,
.reportsBordersTax a:hover,
.reportsBordersAcc a:hover,
.reportsBordersSt a:hover,
.reportsBordersPu a:hover,
.reportsBordersSa a:hover {
  color: white;
  text-decoration: none;
}

@media only screen and (max-width: 768px) {

  /* For mobile phones: */
  .pLeft38 {
    padding-left: 25%
  }

  .pLeft35 {
    padding-left: 5px;
  }

  .reportsBordersFi,
  .reportsBordersTax,
  .reportsBordersAcc,
  .reportsBordersSt,
  .reportsBordersPu,
  .reportsBordersSa {
    width: 100%;
  }
}

/* report incomeStatement */
.inputMinWitdth {
  height: 25px;
}

.inputMinWitdth input {
  min-height: 25px;
  height: 25px;
}

.inputMinWitdth div {
  height: 25px;
}

.inputMinWitdth p {
  line-height: 14px;
  font-size: 14px;
}

.hrSolid {
  opacity: 0.6;
  padding-top: 1px;
  color: black;
  background-color: bla;
}

@media only screen and (max-width: 768px) {

  /* For mobile phones: */
  .inputMinWitdth {
    height: auto;
  }

  .inputMinWitdth p {
    margin-bottom: 4px;
  }

  .inputMinWitdth div .text-left {
    display: flex;
    align-items: flex-end;
  }

  .inputMinWitdth input {
    height: 20px;
  }

  .inputMinWitdth div {
    height: 30px;
  }
}

.companyName {
  justify-content: start;
}

@media only screen and (min-width: 1200px) {
  .companyName {
    justify-content: center;
  }
}

@media only screen and (min-width: 1600px) {
  .companyName {
    justify-content: end;
  }
}

/* textarea Rezize none */

.textArea-none {
  resize: none;
}

.font-70 {
  font-size: 70% !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}